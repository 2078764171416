import React from "react";
import Layout from "../../other/layout";
import Gerez from "../../components/AC-StaticPages/Gerez/Gerez";
import SEO from "../../other/seo";

export default function gerez() {
  return (
    <Layout>
      <SEO title="Gerez" />

      <Gerez />
    </Layout>
  );
}
