import React from "react";
import { Link } from "gatsby";
import styles from "./cta-button.module.css";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";

export default function CTAButton(props) {
  const { url = "/", text, minWidth, margin, fontSize, top } = props;
  return (
    <Link to={url} className={styles.link} style={{ fontSize: fontSize }}>
      {props.text}
      <ArrowForwardIosOutlinedIcon
        style={{
          fontSize: fontSize,
          position: "relative",
          top: top,
          minWidth: minWidth,
          margin: margin
        }}
      />
    </Link>
  );
}
