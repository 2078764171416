import React from "react";
import styles from "./styles.module.css";
import Button from "../components/Button";
import CTAButton from "../components/CTAButton";
import buttons from "../components/button.module.css";

export default function Gerez() {
  const btnMargin = "10px auto";
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Gérez votre entreprise</h1>
        <p>
          Vous pensiez que démarrer votre entreprise avait été difficile, mais
          aujourd'hui, vous faites face au défi encore plus grand de l'exploiter
          jour après jour. Nous sommes lâ pour vous aider! Nos produits et
          services vous permettront de gérer plus efficacement votre entreprise.
          Nous avons des solutions qui libéreront votre horaire afin de vous
          laisser faire ce qui est important pour vous plutôt que de passer
          votre temps au bureau à effectuer toutes sortes de tâches
          administratives.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Enregistrement d'entreprise
            </h2>
            <p>
              À titre d'entrepreneur, vous connaissez l'importance de protéger
              le nom et les actifs de votre compagnie. Ceci est maintenant
              facile et abordable grâce aux services en ligne d'enregistrement
              d'entreprise de Deluxe.
            </p>

            <a
              style={{ margin: btnMargin }}
              href="http://www.nebscorp.ca/docfr/pinc/home.asp"
              target="_blank"
              className={buttons.link}
            >
              Incorporation en ligne (s'ouvre dans une nouvelle fenètre)
            </a>
            <a
              style={{ margin: btnMargin }}
              href="http://www.nebscorp.ca/docfr/ptm/home.asp"
              target="_blank"
              className={buttons.link}
            >
              Marques de commerce (s'ouvre dans une nouvelle fenètre)
            </a>
            <a
              style={{ margin: btnMargin }}
              href="http://www.nebscorp.ca/pmtn/pmtn/home.asp"
              target="_blank"
              className={buttons.link}
            >
              Gestion corporative (s'ouvre dans une nouvelle fenètre)
            </a>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>
              Imprimés d'affaires
            </h2>
            <p>
              Des articles imprimés conçus expressément pour votre entreprise.
              Deluxe offre une gamme étendue de produits indispensables qui
              sauront vous aider à gérer vos affaires.
            </p>
            <div className={styles.column_2}>
              <Button
                text={"Chèques"}
                margin={btnMargin}
                url={"/gerez/cheques/"}
              />
              <Button
                text={"Certificats-cadeaux"}
                margin={btnMargin}
                url={"/developpez/certificats-cadeaux/"}
              />
              <Button
                text={"Papeterie"}
                margin={btnMargin}
                url={"/gerez/papeterie/"}
              />
            </div>
            <div className={styles.column_2}>
              <Button
                text={"Autocollants"}
                margin={btnMargin}
                url={"/developpez/autocollants/"}
              />
              <Button
                text={"Formulaires"}
                margin={btnMargin}
                url={"/gerez/formulaires/"}
              />
              <Button
                text={"Logiciels"}
                margin={btnMargin}
                url={"/gerez/logiciels/"}
              />
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <h2 style={{ color: "unset", fontSize: "1.5em" }}>Bureau mobile</h2>
            <p>
              Nous comprenons que vous êtes occupés et qu'il est important pour
              vous de rester bien au courant de tout ce qui touche à votre
              entreprise. Avec Deluxe, il est très facile d'accéder à votre
              courrier électronique et à des outils de gestion du temps où que
              vous soyez et à tout moment.
            </p>
            <Button
              text={"Courriel classe affaires"}
              margin={btnMargin}
              url={
                "/developpez/services-web/forfaits-courriels/courriel-classe-affaires/"
              }
            />
            <Button
              text={"Organisez"}
              margin={btnMargin}
              url={"/developpez/services-web/forfaits-courriels/organisez/"}
            />
            <Button
              text={"Synchronisez"}
              margin={btnMargin}
              url={"/developpez/services-web/forfaits-courriels/synchronisez/"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
